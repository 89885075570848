import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import IconCard from "../components/Cards/IconCard";
import AccountBoxRoundedIcon from "@mui/icons-material/AccountBoxRounded";
import React from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { UserDataContext } from "../lib/contexts/UserDataContext";

export default function Profil() {
    const { userData } = React.useContext(UserDataContext);
    const [tab, setTab] = React.useState(0);
    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    if (userData)
        return (
            <IconCard title={"Mon Profil"} icon={AccountBoxRoundedIcon}>
                <Tabs value={tab} onChange={handleTabs}>
                    <Tab label="Mes informations" />
                    <Tab label="À propos" />
                </Tabs>
                {tab === 0 && (
                    <Stack p={2}>
                        <Typography>
                            <b>Nom : </b>
                            {userData.nom}
                        </Typography>
                        <Typography>
                            <b>Prénom : </b> {userData.prenom}
                        </Typography>
                        <Typography>
                            <b>Poste : </b> {userData.poste}
                        </Typography>
                        <Typography>
                            <b>Groupe : </b>{" "}
                            {userData.groupe ? userData.groupe : "Aucun"}
                        </Typography>
                        <Typography>
                            <b>E-mail : </b> {userData.mail}
                        </Typography>
                    </Stack>
                )}
                {tab === 1 && (
                    <Stack p={2} spacing={1}>
                        <Typography>
                            Le Portail de formation est une application Web,
                            développer par Arthur Moisant, dans le cadre de la
                            formation des apprentis boucher de l'École
                            Professionnelle de la Boucherie de Paris.
                        </Typography>
                        <br />
                        <Typography>
                            Afin de pousuivre son développement et d'améliorer
                            la platforme, n'hésitez pas à donner votre avis :
                        </Typography>
                        <br />
                        <Typography
                            display="flex"
                            justifyContent="space-between"
                        >
                            Pour toutes informations complémentaire,
                            contactez-nous :
                        </Typography>
                        <Button
                            variant="contained"
                            size="small"
                            color="tertiary"
                            href="mailto:amoisant@epb-paris.fr"
                        >
                            Contact
                        </Button>
                    </Stack>
                )}
            </IconCard>
        );
}
