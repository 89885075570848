import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";
import React from "react";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import Typography from "@mui/material/Typography";
import RessourceCardHeader from "./RessourceCardItems/RessourceCardHeader";
import RessourceCardCover from "./RessourceCardItems/RessourceCardCover";

export default function RessourceCard({ data }) {
    const navigate = useNavigate();

    return (
        <Card variant="outlined" square>
            <RessourceCardHeader data={data} />
            <Divider />
            <Box
                component={CardActionArea}
                onClick={(e) =>
                    navigate("./" + data.id, { state: { play: false } })
                }
            >
                <RessourceCardCover data={data} />
                <Chip
                    label={
                        data.categorie === "Video"
                            ? "Durée " + data.size
                            : data.childrenCount.split(",").length +
                              1 +
                              " Morceaux 3D"
                    }
                    size="small"
                    sx={{
                        position: "absolute",
                        bottom: 5,
                        bgcolor: "#000000",
                        color: "whitesmoke",
                        right: 5,
                        opacity: 0.5,
                    }}
                />
                <Typography
                    variant="subtitle2"
                    sx={{
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        p: 0.5,
                        pr: 3,
                        fontStyle: "italic",
                        borderTopRightRadius: 10,
                        background:
                            "linear-gradient(90deg, rgba(255,255,255,0.8) 50%, rgba(255,255,255,0) 100%)",
                    }}
                >
                    {data.views ? data.views : "0"} vues
                </Typography>
            </Box>
        </Card>
    );
}
