// Ce fichier gère l'enregistrement du service worker dans l'application

const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
        // [::1] est l'adresse localhost pour IPv6
        window.location.hostname === "[::1]" ||
        // 127.0.0.1/8 est considéré comme localhost pour IPv4
        window.location.hostname.match(/^127(?:\.\d+){0,2}\.\d+$/)
);

export function register(config) {
    if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
        const publicUrl = new URL(process.env.PUBLIC_URL, window.location.href);
        if (publicUrl.origin !== window.location.origin) {
            // L'application est sur un autre domaine, donc ne pas enregistrer le service worker
            return;
        }

        window.addEventListener("load", () => {
            const swUrl = `${process.env.PUBLIC_URL}/service-worker.js`;

            if (isLocalhost) {
                // Ceci est une machine locale, vérifiez le service worker et affichez des logs supplémentaires.
                checkValidServiceWorker(swUrl, config);

                // Ajouter des logs en local pour savoir si le service worker a été trouvé
                navigator.serviceWorker.ready.then(() => {
                    console.log(
                        "Le service worker est actif et prêt à servir les ressources depuis le cache."
                    );
                });
            } else {
                // Enregistrer le service worker sur un environnement de production
                registerValidSW(swUrl, config);
            }
        });
    }
}

function registerValidSW(swUrl, config) {
    navigator.serviceWorker
        .register(swUrl)
        .then((registration) => {
            registration.onupdatefound = () => {
                const installingWorker = registration.installing;
                if (installingWorker == null) {
                    return;
                }
                installingWorker.onstatechange = () => {
                    if (installingWorker.state === "installed") {
                        if (navigator.serviceWorker.controller) {
                            // Nouvelle mise à jour disponible
                            console.log(
                                "Nouvelle mise à jour disponible. Elle sera utilisée après un rechargement."
                            );

                            if (config && config.onUpdate) {
                                config.onUpdate(registration);
                            }
                        } else {
                            // Le contenu est mis en cache pour une utilisation hors ligne.
                            console.log(
                                "Le contenu est mis en cache pour une utilisation hors ligne."
                            );

                            if (config && config.onSuccess) {
                                config.onSuccess(registration);
                            }
                        }
                    }
                };
            };
        })
        .catch((error) => {
            console.error(
                "Échec de l'enregistrement du service worker:",
                error
            );
        });
}

function checkValidServiceWorker(swUrl, config) {
    fetch(swUrl, {
        headers: { "Service-Worker": "script" },
    })
        .then((response) => {
            // S'il existe un service worker sur cet URL, continuez à le gérer
            const contentType = response.headers.get("content-type");
            if (
                response.status === 404 ||
                (contentType != null &&
                    contentType.indexOf("javascript") === -1)
            ) {
                // Aucun service worker trouvé, désactivez l'ancien.
                navigator.serviceWorker.ready.then((registration) => {
                    registration.unregister().then(() => {
                        window.location.reload();
                    });
                });
            } else {
                // Service worker valide, l'enregistrer.
                registerValidSW(swUrl, config);
            }
        })
        .catch(() => {
            console.log(
                "Aucune connexion à Internet. Le service worker fonctionne en mode hors ligne."
            );
        });
}

export function unregister() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.ready
            .then((registration) => {
                registration.unregister();
            })
            .catch((error) => {
                console.error(
                    "Erreur lors de la désinscription du service worker:",
                    error
                );
            });
    }
}
