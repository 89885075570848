import SvgIcon from "@mui/material/SvgIcon";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";

import { useNavigate } from "react-router-dom";
import { useSelectedPage } from "hooks/useSelectedPage";

export default function Navbar() {
    const isSelect = useSelectedPage();
    const navTo = useNavigate();

    return (
        <Tabs
            value={isSelect.selected}
            onChange={(event, newValue) => {
                navTo(newValue);
            }}
            centered
            indicatorColor="secondary"
            sx={{
                bgcolor: "white",
                py: 1,

                boxShadow:
                    "rgba(0, 0, 0, 0.12) 0px -10px 10px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px",
                "& .MuiTabs-indicator": {
                    display: "flex",
                    justifyContent: "center",
                    maxWidth: 40,
                    ml: 4,
                },
                "& .MuiTabs-indicatorSpan": {
                    backgroundColor: "#635ee7",
                },
            }}
        >
            {isSelect &&
                isSelect.repertoire.map((route) => (
                    <Tab
                        key={route.name}
                        value={route.path}
                        label={route.name}
                        iconPosition="start"
                        icon={<SvgIcon component={route.icon} />}
                        sx={{
                            minHeight: 0,
                        }}
                    />
                ))}
        </Tabs>
    );
}
