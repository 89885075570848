import React from "react";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import useListContents from "hooks/useRessourcesList";
import RessourceCard from "./RessourceCard";
import { FilterContext } from "contexts/FilterContext";
export default function RessourcesSummary({ type }) {
    const content = useListContents();
    const { matchesFilter } = React.useContext(FilterContext);

    if (content && content.liste && Array.isArray(content.liste)) {
        return (
            <Grid2
                container
                width={"100%"}
                mx="auto"
                mt={1}
                columnSpacing={{
                    xs: 0,
                    sm: 2,
                    md: 5,
                    lg: 5,
                    xl: 5,
                }}
                rowSpacing={{
                    xs: 1,
                    sm: 5,
                    md: 5,
                    lg: 5,
                    xl: 5,
                }}
                xs={12}
            >
                {content.liste.filter(matchesFilter).map(
                    (item) =>
                        item.categorie === type && (
                            <Grid2 key={item.name} xs={12} sm={6} md={3}>
                                <RessourceCard data={item} />
                            </Grid2>
                        )
                )}
            </Grid2>
        );
    }

    return null; // ou un composant de chargement, ou un message d'erreur
}
