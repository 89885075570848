import { Outlet } from "react-router-dom";
import Container from "@mui/material/Container";
import useMediaQuery from "@mui/material/useMediaQuery";

import Header from "components/Header";
import Footer from "components/Footer";
import Navbar from "components/Navbar";
import OpeningApp from "../components/Backdrop/OpeningApp";
import React from "react";
import {
    AuthenticatedTemplate,
    UnauthenticatedTemplate,
} from "@azure/msal-react";
import Auth from "components/Auth/Auth";
import InstallApp from "../components/Snackbar/InstallApp";
import { UserDataProvider } from "../lib/contexts/UserDataContext";

export default function App() {
    const matches = useMediaQuery("(max-width:600px)");
    const [open, setOpen] = React.useState(true);

    React.useEffect(() => {
        open && setTimeout(() => setOpen(false), 6500);
    }, [open]);

    return (
        <UserDataProvider>
            <Header />

            <AuthenticatedTemplate>
                {!matches && <Navbar />}
                <Container disableGutters maxWidth="false" sx={{ mb: 8 }}>
                    <Outlet />
                </Container>
                {matches && <Footer />}
                <InstallApp />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <OpeningApp open={open} />
                <Auth />
            </UnauthenticatedTemplate>
        </UserDataProvider>
    );
}
