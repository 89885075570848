import Card from "@mui/material/Card";
import RessourceCardCover from "components/Resources/RessourceCardItems/RessourceCardCover";
import Typography from "@mui/material/Typography";
import React from "react";
import CardHeader from "@mui/material/CardHeader";
import CardActionArea from "@mui/material/CardActionArea";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";

export default function CardIconPlay({ data }) {
    const nav = useNavigate();

    return (
        <Card
            elevation={3}
            sx={{
                position: "relative",
                flex: "0 0 auto",
                height: "40vw",
                width: "40vw",
            }}
        >
            <CardActionArea
                onClick={() =>
                    nav(data.categorie + "/" + data.id, {
                        state: { play: false },
                    })
                }
            >
                <Chip
                    label={data.categorie}
                    size="small"
                    sx={{
                        position: "absolute",
                        top: 2,
                        left: 2,
                        fontSize: 10,
                        height: 13,
                        bgcolor: "#000000",
                        color: "whitesmoke",
                        opacity: 0.5,
                    }}
                />
                <RessourceCardCover
                    width={data.categorie === "Video" ? "100%" : "57%"}
                    data={data}
                />
                <CardHeader
                    sx={{
                        p: 0,
                        pt: 0.4,
                        pl: 0.4,
                    }}
                    title={
                        <Typography fontSize={14} color={"primary.main"}>
                            {data.name}
                        </Typography>
                    }
                />
            </CardActionArea>
        </Card>
    );
}
