import Stack from "@mui/material/Stack";
import { useLoaderData, useLocation, useNavigate } from "react-router-dom";
import RessourceCardCover from "../components/Resources/RessourceCardItems/RessourceCardCover";
import IconButton from "@mui/material/IconButton";
import ArrowBackRoundedIcon from "@mui/icons-material/ArrowBackRounded";
import Slide from "@mui/material/Slide";
import React from "react";
import RessourceCardHeader from "../components/Resources/RessourceCardItems/RessourceCardHeader";
import Button from "@mui/material/Button";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import Typography from "@mui/material/Typography";
import ViewInArSharpIcon from "@mui/icons-material/ViewInArSharp";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Card from "@mui/material/Card";
import Grid2 from "@mui/material/Unstable_Grid2/Grid2";
import CardActionArea from "@mui/material/CardActionArea";
import { getEntityTable, tableAnatomie3D } from "../lib/storage/tableStorage";
import ModelViewer from "components/ModelViewer";

export default function Video() {
    const [isOpen, setIsOpen] = React.useState(true);
    const nav = useNavigate();
    const locationState = useLocation().state.play;
    function handleExit() {
        setIsOpen(false);
        nav("..");
    }
    const [tab, setTab] = React.useState(0);
    const handleTabs = (event, newValue) => {
        setTab(newValue);
    };

    const anatomie3D = useLoaderData();

    const handleModelViewer = (e, item) => {
        item && handleModele3D(item);
        return nav(".", { state: { play: !locationState } });
    };
    const [selectedMorceaux, setSelectedMorceaux] = React.useState(null);
    const handleModele3D = (id) => {
        getEntityTable("RepertoireModele3D", id).then((r) =>
            setSelectedMorceaux(r)
        );
    };

    React.useEffect(() => {
        if (!selectedMorceaux) {
            handleModele3D(anatomie3D.rowKey);
        }
    }, [selectedMorceaux, anatomie3D]);

    const [listMorceaux, setListeMorceaux] = React.useState([]);
    React.useEffect(() => {
        if (!listMorceaux.length) {
            setListeMorceaux([
                {
                    id: anatomie3D.rowKey,
                    name: anatomie3D.name,
                    cover: anatomie3D.coverFileName,
                },
            ]);
            anatomie3D.childrenCount.split(",").map((item) =>
                getEntityTable("RepertoireModele3D", item).then((r) =>
                    setListeMorceaux((prev) => [
                        ...prev,
                        {
                            id: r.rowKey,
                            name: r.name,
                            cover: r.fileCover,
                        },
                    ])
                )
            );
        }
    }, [listMorceaux, anatomie3D]);

    const [viewed, setViewed] = React.useState(false);
    React.useEffect(() => {
        const handleViews = () => {
            setViewed(true);
            const action = [
                [
                    "update",
                    {
                        partitionKey: anatomie3D.partitionKey,
                        rowKey: anatomie3D.rowKey,
                        views: anatomie3D.views ? anatomie3D.views + 1 : 1,
                    },
                    "Merge",
                ],
            ];
            tableAnatomie3D.submitTransaction(action);
            //onView(videoId);  // Appelle la fonction pour compter la vue
        };

        if (locationState && !viewed) {
            setTimeout(() => handleViews(), [60000]);
        }
    }, [viewed, locationState, anatomie3D]);

    return (
        <Slide direction="left" in={isOpen}>
            <Stack position="relative" bgcolor={"#FFFFFF"}>
                <ModelViewer
                    open={locationState}
                    handle={handleModelViewer}
                    modele3D={selectedMorceaux}
                    listMorceaux={listMorceaux}
                    handleModele3D={handleModele3D}
                    selectedMorceaux={selectedMorceaux}
                />
                <IconButton
                    onClick={handleExit}
                    sx={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                    }}
                >
                    <ArrowBackRoundedIcon />
                </IconButton>
                <IconButton
                    sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                    }}
                >
                    <StarRoundedIcon />
                </IconButton>

                <RessourceCardCover
                    data={{
                        categorie: "Anatomie3D",
                        illustration: anatomie3D.coverFileName,
                    }}
                    height={260}
                />
                <RessourceCardHeader
                    data={{
                        name: anatomie3D.name,
                        labels: anatomie3D.labels.split(","),
                    }}
                />

                <Button
                    color="info"
                    variant="contained"
                    startIcon={<ViewInArSharpIcon />}
                    sx={{ m: 2, lineHeight: 1.1 }}
                    aria-hidden="true"
                    onClick={(e) => handleModelViewer(e, anatomie3D.rowKey)}
                >
                    Visionner
                </Button>

                <Box mx={2}>
                    <Tabs value={tab} onChange={handleTabs}>
                        <Tab label="Description" />
                        <Tab label="Nomenclature" />
                    </Tabs>
                    <Divider sx={{ mb: 1 }} />
                    {tab === 0 ? (
                        <Typography pb={9}>{anatomie3D.description}</Typography>
                    ) : (
                        <Grid2 container xs={12} spacing={1} mx="auto" pb={5}>
                            {listMorceaux.map((item) => (
                                <Grid2 key={item.name} xs={6}>
                                    <Card
                                        variant={"outlined"}
                                        sx={{
                                            borderColor:
                                                selectedMorceaux.rowKey ===
                                                    item.id && "info.main",
                                        }}
                                        //position="relative"
                                    >
                                        <CardActionArea
                                            onClick={(e) =>
                                                handleModelViewer(e, item.id)
                                            }
                                        >
                                            <Typography
                                                variant="h6"
                                                fontSize={14}
                                                height={22}
                                                color="primary.main"
                                                position="absolute"
                                                top={3}
                                                left={5}
                                            >
                                                {item.name}
                                            </Typography>

                                            <RessourceCardCover
                                                //height={80}
                                                data={{
                                                    categorie: "Anatomie3D",
                                                    illustration: item.cover,
                                                }}
                                            />
                                        </CardActionArea>
                                    </Card>
                                </Grid2>
                            ))}
                        </Grid2>
                    )}
                </Box>
            </Stack>
        </Slide>
    );
}
