import { createBrowserRouter } from "react-router-dom";
import { loaderAnatomie3D, loaderVideo } from "./loader";

//Imports Routes
import App from "routes/App";
import DashBoard from "routes/Dashboard";
import Profil from "routes/Profil";
import Video from "routes/Video";
import Anatomie3D from "routes/Anatomie3D";

import RessourcesPage from "components/Resources/RessourcesPage";

//Imports Icons
import HomeIcon from "@mui/icons-material/Home";
import VideoLibraryOutlinedIcon from "@mui/icons-material/VideoLibraryOutlined";
import ViewInArSharpIcon from "@mui/icons-material/ViewInArSharp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";

/* IMPORT GESTIONNAIRE 
import Gestionnaire from "routes/Gestionnaire";
import SettingsIcon from "@mui/icons-material/Settings";
import PeopleAltRoundedIcon from "@mui/icons-material/PeopleAltRounded";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import ListUsers from "routes/children_Gestionnaire/Users/ListUsers";
import Database from "routes/children_Gestionnaire/Users/Database";
import Contenus from "routes/children_Gestionnaire/Resources/ListeResources";
import FormatListBulletedRoundedIcon from "@mui/icons-material/FormatListBulletedRounded";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import DeleteRoundedIcon from "@mui/icons-material/DeleteRounded";
import ListeContenus from "routes/children_Gestionnaire/Resources/ListeContenus";
import NotAvailaible from "../../components/Alert/NotAvailable";
import Users from "routes/children_Gestionnaire/Users/Users";*/

const handleContent = () => {
    return true;
};

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        //errorElement
        children: [
            {
                index: true,
                path: "/",
                element: <DashBoard />,
                //
                name: "Tableau de bord",
                icon: HomeIcon,
                //
                footer: true,
                menu: true,
            },
            {
                path: "Video",
                //
                name: "Vidéo",
                icon: VideoLibraryOutlinedIcon,
                //
                navbar: true,
                footer: true,
                //
                children: [
                    {
                        index: true,
                        element: <RessourcesPage />,
                    },
                    {
                        path: ":ressource",
                        element: <Video />,
                        loader: loaderVideo,
                    },
                ],
            },
            {
                path: "Anatomie3D",
                //
                name: "Anatomie 3D",
                icon: ViewInArSharpIcon,
                //
                navbar: true,
                footer: true,
                //
                shouldRevalidate: handleContent,
                children: [
                    {
                        index: true,
                        element: <RessourcesPage />,
                    },
                    {
                        path: ":ressource",
                        element: <Anatomie3D />,
                        loader: loaderAnatomie3D,
                    },
                ],
            },
            {
                path: "profil",
                element: <Profil />,
                //
                name: "Profil",
                icon: AccountCircleOutlinedIcon,
                //
                navbar: true,
                footer: true,
            } /*
            {
                path: "gestionnaire",
                element: <Gestionnaire />,
                //
                name: "Gestionnaire",
                icon: SettingsIcon,
                //
                navbar: true,
                footer: false,
                //
                children: [
                    {
                        index: true,
                        element: (
                            <>
                                <Contenus />
                                <Users />
                            </>
                        ),
                    },
                    {
                        path: "ressources",
                        element: <Contenus />,
                        children: [
                            {
                                path: "ajouter",
                                element: <NotAvailaible />,
                                //
                                name: "Ajouter du contenus",
                                icon: AddCircleOutlineRoundedIcon,
                            },
                            {
                                path: "modifier",
                                element: <NotAvailaible />,
                                //
                                name: "Modifier du contenus",
                                icon: EditRoundedIcon,
                            },
                            {
                                path: "supprimer",
                                element: <NotAvailaible />,
                                //
                                name: "Supprimer du contenus",
                                icon: DeleteRoundedIcon,
                            },
                            {
                                path: "liste",
                                element: <ListeContenus />,
                                //
                                name: "Liste du contenus",
                                icon: FormatListBulletedRoundedIcon,
                            },
                        ],
                    },
                    {
                        path: "users",
                        element: <Users />,
                        children: [
                            {
                                path: "liste",
                                element: <ListUsers />,
                                //
                                name: "Voir tous les utilisateurs",
                                icon: PeopleAltRoundedIcon,
                            },
                            {
                                path: "database",
                                element: <Database />,
                                //
                                name: "Gestion Base de donnée",
                                icon: MemoryRoundedIcon,
                            },
                        ],
                    },
                ],
            },*/,
        ],
    },
]);

export default router;
