import { InteractionRequiredAuthError } from "@azure/msal-browser";

export const callMsGraph = async (instance, account) => {
    const request = {
        scopes: ["User.Read"],
        account: account,
    };

    const response = await instance
        .acquireTokenSilent(request)
        .catch(async (e) => {
            if (e instanceof InteractionRequiredAuthError) {
                return instance.acquireTokenPopup(request);
            }
        });

    const graphResponse = await fetch("https://graph.microsoft.com/v1.0/me", {
        headers: {
            Authorization: `Bearer ${response.accessToken}`,
        },
    });

    return graphResponse.json();
};
