import createTheme from "@mui/material/styles/createTheme";
import { frFR } from "@mui/material/locale";
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";

export let theme = createTheme(
    {
        palette: {
            mode: "light",
            primary: {
                main: "#182560",
                light: "#46507F",
                dark: "#101943",
            },
            secondary: {
                main: "#9e8638",
                contrastText: "#FFFFFF",
                light: "#B19E5F",
                dark: "#6E5D27",
            },
            tertiary: {
                main: "#8E44AD",
                light: "#A469BD",
                dark: "#632F79",
                contrastText: "#FFFFFF",
            },
            info: {
                main: "#17A2B8",
                light: "#45B4C6",
                dark: "#107180",
            },
            error: {
                main: "#c9302c",
                light: "#D35956",
                dark: "#8C211E",
            },
            warning: {
                main: "#ffc107",
                light: "#FFCD38",
                dark: "#B28704",
                contrastText: "#FFFFFF",
            },
            success: {
                main: "#28A745",
                light: "#28A745",
                dark: "#1C7430",
            },

            background: {
                default: "#eceff1",
            },
        },
    },
    frFR
);
