import CardHeader from "@mui/material/CardHeader";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import SvgIcon from "@mui/material/SvgIcon";
import BrokenImageIcon from "@mui/icons-material/BrokenImage";

export default function IconCard({ title, icon, children }) {
    return (
        <Card
            square
            elevation={5}
            sx={{ position: "relative", overflow: "visible", mt: 5 }}
        >
            <CardHeader
                sx={{
                    background: "linear-gradient(60deg, #6E5D27,#9e8638)",
                    boxShadow:
                        "0 12px 20px -10px rgba(177, 158, 95, 0.28), 0 4px 20px 0px rgba(0, 0, 0, 0.12), 0 7px 8px -5px rgba(177, 158, 95, 0.2)",
                    color: "white",
                    position: "absolute",
                    width: "fit-content",
                    fontSize: 35,
                    borderRadius: 1,
                    p: 1.2,
                    left: 15,
                    top: -15,
                    "& .MuiCardHeader-avatar": {
                        mr: 0,
                    },
                }}
                avatar={
                    <SvgIcon
                        fontSize="inherit"
                        component={icon ? icon : BrokenImageIcon}
                    />
                }
            />

            <CardContent sx={{ py: 1 }}>
                <Typography
                    component="div"
                    variant="h5"
                    color="primary"
                    ml={7.5}
                >
                    {title}
                </Typography>
                <Divider variant="inset" />
            </CardContent>
            <CardContent sx={{ py: 1 }}>{children}</CardContent>
        </Card>
    );
}
