import { getEntityTable } from "storage/tableStorage";

export function loaderVideo({ params }) {
    const ressource = getEntityTable("RessourcesVideo", params.ressource);

    return ressource;
}

export function loaderAnatomie3D({ params }) {
    const ressource = getEntityTable("RessourcesAnatomie3D", params.ressource);

    return ressource;
}
