import Button from "@mui/material/Button";
import FilterListIcon from "@mui/icons-material/FilterList";

export default function FilterButton({ isOpen, ...props }) {
    return (
        <Button
            {...props}
            sx={{
                fontWeight: 900,
                height: "fit-content",
                p: "1px 5px",
                //boxShadow: 5,

                "& .MuiButton-startIcon": {
                    transitionDuration: "0.5s",
                    msTransitionProperty: "transform",
                    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
                },
            }}
            startIcon={<FilterListIcon />}
        >
            Filtre
        </Button>
    );
}
/*
 */
