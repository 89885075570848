import { BlobServiceClient } from "@azure/storage-blob";

//// Enregistrement Storage Portail Apprenant
const sasToken = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN;
const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;
const blobUrl = `https://${storageAccountName}.blob.core.windows.net/?${sasToken}`;
const blobService = new BlobServiceClient(blobUrl);

/// Function Ressources (lié à l'ancien storage)
export function getContentIllustration(categorie, fileName) {
    const containerName =
        categorie === "Video" ? "illustration-video" : "illustration-modele3d";

    const containerClient = blobService.getContainerClient(containerName);

    const illustration = containerClient.getBlockBlobClient(fileName);

    return illustration.exists && illustration.url;
}

const blobVideo = blobService.getContainerClient("video");
export function getBlobVideo(id) {
    return blobVideo.getBlockBlobClient(id).url;
}

const blobModele3D = blobService.getContainerClient("modele3d");
export function getBlobModele3D(id) {
    return blobModele3D.getBlockBlobClient(id).url;
}

//// Enregistrement Storage Portail EPB

const sasToken2 = process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN_2;
const storageAccountName2 = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME_2;
const blobUrl2 = `https://${storageAccountName2}.blob.core.windows.net/?${sasToken2}`;
const blobService2 = new BlobServiceClient(blobUrl2);

export const blobUsersData = blobService2.getContainerClient("usersdata");

////////////////////////////////////////////////////////////////
/* Archives 
export const createBlobInContainer = async (
    file,
    container,
    coverName,
    coverType
) => {
    const containerClient = blobService.getContainerClient(container);

    const blobClient = containerClient.getBlockBlobClient(
        !coverName ? file.name : coverName
    );

    const options = {
        blobHTTPHeaders: {
            blobContentType: !coverType ? file.type : coverType,
        },
    };

    await blobClient.uploadData(file, options);
};

export const getBlobsInContainer = async (containerName) => {
    const returnedBlobUrls = [];

    const containerClient = blobService.getContainerClient(containerName);

    for await (const blob of containerClient.listBlobsFlat()) {
        returnedBlobUrls.push(blob.name);
    }

    return returnedBlobUrls;
};
*/
