import React from "react";
import router from "router/router";
import { useLocation } from "react-router-dom";

export function useSelectedPage() {
    const [isSelect, setIsSelect] = React.useState("/");

    const location = useLocation();
    const repertoire = router.routes[0].children;

    const handleiIsSelect = () => {
        repertoire.map(
            (item) =>
                location.pathname.includes(item.path) && setIsSelect(item.path)
        );
    };

    React.useEffect(() => {
        location && handleiIsSelect();
    });

    return {
        repertoire: repertoire,
        selected: isSelect,
    };
}
