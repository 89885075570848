import {
    TableServiceClient,
    AzureSASCredential,
    TableClient,
} from "@azure/data-tables";

const storageAccountName = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME;

const storageAccountName2 = process.env.REACT_APP_AZURE_STORAGE_RESOURCE_NAME_2;

export const sasToken = new AzureSASCredential(
    process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN
);

export const sasToken2 = new AzureSASCredential(
    process.env.REACT_APP_AZURE_STORAGE_SAS_TOKEN_2
);

export const tableUrl = `https://${storageAccountName}.table.core.windows.net/`;

export const tableUrl2 = `https://${storageAccountName2}.table.core.windows.net/`;

export const tableService = new TableServiceClient(tableUrl, sasToken);

export const getTable = (tableName) => {
    return new TableClient(tableUrl, tableName, sasToken);
};

export const getEntityTable = (tableName, entityID) => {
    const tableRessource = new TableClient(tableUrl, tableName, sasToken);

    const entityTable = tableRessource.getEntity(entityID, entityID);

    return entityTable;
};

export const getEntityTable2 = (tableName, entityID) => {
    const tableRessource = new TableClient(tableUrl2, tableName, sasToken2);

    const entityTable = tableRessource.getEntity(entityID, entityID);

    return entityTable;
};

export const TableUsers = new TableClient(tableUrl2, "Users", sasToken2);

///////////////////////////////////////////////////////////////////////////////

export const tableVideos = new TableClient(
    tableUrl,
    "RessourcesVideo",
    sasToken
);

export const tableAnatomie3D = new TableClient(
    tableUrl,
    "RessourcesAnatomie3D",
    sasToken
);
/*
 const tableModele3D = new TableClient(
    tableUrl,
    "RepertoireModele3D",
    sasToken
);


 const TableFeedBack = new TableClient(tableUrl, "FeedBack", sasToken);

 const TableChapitre = (tableName) => {
    const table = new TableClient(tableUrl, tableName, sasToken);
    return table;
};

 const createEntityTable = async (tableName, entity, options) => {
    const tableRessource = new TableClient(tableUrl, tableName, sasToken);

    tableRessource.createEntity(entity, options);
};*/
