import { getContentIllustration } from "storage/blobStorage";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import CardMedia from "@mui/material/CardMedia";

export default function RessourceCardCover({ data, height, width }) {
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [cover, setCover] = React.useState("");

    React.useEffect(() => {
        const srcCover = getContentIllustration(
            data.categorie,
            data.illustration
        );
        setCover(srcCover);
    }, [data]);

    return React.useMemo(() => {
        return (
            <>
                {!isLoaded && (
                    <Skeleton
                        variant="rectangular"
                        animation="wave"
                        height={
                            height
                                ? height
                                : data.categorie === "Video"
                                ? 210
                                : 250
                        }
                        width={"100%"}
                    />
                )}

                <CardMedia
                    image={cover}
                    component="img"
                    onLoad={() => setIsLoaded(true)}
                    sx={{
                        width: width ? width : "100%",
                        maxWidth: "900px",
                        mx: "auto",
                        display: isLoaded ? "block" : "none",
                    }}
                />
            </>
        );
    }, [data, isLoaded, cover, height, width]);
}
