import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

export default function Titre({ children }) {
    return (
        <>
            <Typography
                variant="h4"
                sx={{
                    fontWeight: 400,
                    lineHeight: 1.334,
                    display: "flex",
                    letterSpacing: "0em",
                    color: "primary.main",
                    mt: 1,
                }}
            >
                {children}
            </Typography>
            <Divider
                sx={{
                    borderColor: "secondary.main",
                    borderWidth: 1,
                    width: "10%",
                    marginLeft: 2,
                    mb: 1,
                }}
            />
        </>
    );
}
