import { useMsal } from "@azure/msal-react";
import React from "react";
import { callMsGraph } from "../auth/graphService";
import { TableUsers } from "../storage/tableStorage";

export default function useUserData() {
    const [userData, setUserData] = React.useState(null);
    const { instance, accounts } = useMsal();

    React.useEffect(() => {
        if (accounts.length > 0) {
            callMsGraph(instance, accounts[0]).then((r) => {
                const id = r.surname + r.givenName;

                TableUsers.getEntity(id, id).then((u) => {
                    setUserData({
                        nom: u.nom,
                        prenom: u.prenom,
                        mail: u.mail,
                        poste: u.poste,
                        groupe: u.groupe,
                        id: u.id,
                        register: u.register,
                        favorisVideo: u.favorisVideo,
                        favorisAnatomie3D: u.favorisAnatomie3D,
                    });
                });
            });
        }
    }, [accounts, instance]);

    return { userData };
}
