import React from "react";
import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
//import { MsalProvider } from "@azure/msal-react";
import CssBaseline from "@mui/material/CssBaseline/CssBaseline";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import { theme } from "theme/theme";
//import instanceMSAL from "Config/msal";
import router from "router/router";
import "theme/style.css";
import { MsalProvider } from "@azure/msal-react";
import instanceMSAL from "auth/msalConfig";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

function Index() {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <MsalProvider instance={instanceMSAL}>
                <RouterProvider router={router} />
            </MsalProvider>
        </ThemeProvider>
    );
}

const rootDom = document.getElementById("root");
const root = createRoot(rootDom);
root.render(<Index />);

// Enregistrer le service worker pour activer PWA
serviceWorkerRegistration.register();
