export function openFullscreen(video) {
    if (video.requestFullscreen) {
        video.requestFullscreen();
    } else if (video.mozRequestFullScreen) {
        video.mozRequestFullScreen();
    } else if (video.webkitRequestFullscreen) {
        video.webkitRequestFullscreen();
    } else if (video.msRequestFullscreen) {
        video.msRequestFullscreen();
    } else if (video.webkitEnterFullscreen) {
        video.webkitEnterFullscreen();
    }
}

export function openVideo(video, time) {
    if (time) {
        video.currentTime = time;
        openFullscreen(video);
        video.play();
    } else {
        openFullscreen(video);
        video.play();
    }
}

export function exitFullScreenVideo(videoRef) {
    const fullscreenChangeHandler = () => {
        if (document.fullscreenElement !== videoRef.current) {
            videoRef.current.pause();
        }
    };

    document.addEventListener("fullscreenchange", fullscreenChangeHandler);
    return () => {
        document.removeEventListener(
            "fullscreenchange",
            fullscreenChangeHandler
        );
    };
}
