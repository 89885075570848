import React from "react";
import { getTable } from "storage/tableStorage";

function useChapitresVideo(name) {
    const [chapitres, setChapitres] = React.useState(null);

    const listeChapitres = getTable(name);

    async function loadChapitre() {
        setChapitres([]);

        for await (const chapitre of listeChapitres.listEntities()) {
            setChapitres((prev) => [...prev, chapitre]);
        }
    }

    React.useEffect(() => {
        name && !chapitres && loadChapitre();
    });

    return chapitres ? chapitres : [];
}

export default useChapitresVideo;
