import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import React from "react";

export default function InstallApp() {
    const [deferredPrompt, setDeferredPrompt] = React.useState(null);
    const [open, setOpen] = React.useState(false);

    React.useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault(); // Empêcher l'affichage automatique de la boîte de dialogue
            setDeferredPrompt(e); // Stocker l'événement pour déclencher plus tard
            setOpen(true); // Afficher la snackbar
        };

        window.addEventListener(
            "beforeinstallprompt",
            handleBeforeInstallPrompt
        );

        return () =>
            window.removeEventListener(
                "beforeinstallprompt",
                handleBeforeInstallPrompt
            );
    }, []);

    const handleInstallClick = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt(); // Afficher la boîte de dialogue d'installation
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("L'utilisateur a accepté l'installation");
                } else {
                    console.log("L'utilisateur a refusé l'installation");
                }
                setDeferredPrompt(null); // Réinitialiser deferredPrompt
                setOpen(false); // Fermer la snackbar
            });
        }
    };

    const handleClose = () => {
        setOpen(false); // Fermer la snackbar
    };
    return (
        <Snackbar
            open={open}
            onClose={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            autoHideDuration={10000}
            sx={{
                bottom: "0 !important",
                right: "0 !important",
                left: "0 !important",
                "& .MuiPaper-root": {
                    bgcolor: "primary.main",
                    p: 1,
                    width: "100%",
                    borderRadius: 0,
                },
            }}
            message={
                <Typography>
                    Vous pouvez installer le portail sur votre appareil.
                </Typography>
            }
            action={
                <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleInstallClick}
                    sx={{ mr: 1 }}
                >
                    Installer
                </Button>
            }
        ></Snackbar>
    );
}
