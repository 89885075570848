import Collapse from "@mui/material/Collapse";
import Chip from "@mui/material/Chip";
import AddCircleOutlineRoundedIcon from "@mui/icons-material/AddCircleOutlineRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import { FilterContext } from "../../lib/contexts/FilterContext";
import React from "react";

export default function RessourcesFilter({ isOpen }) {
    const { filter, handleFilter } = React.useContext(FilterContext);

    const filters = [
        "CAP",
        "BP",
        "BOEUF",
        "VEAU",
        "AGNEAU",
        "VOLAILLE",
        "COUPE",
        "DÉSOSSAGE",
        "PARAGE",
        "HABILLAGE",
        "FICELAGE",
        "ABATS",
        "AIGUISAGE",
    ];

    return (
        <Collapse in={isOpen}>
            <Divider variant="middle" />
            <Stack spacing={1} direction="row" flexWrap="wrap" useFlexGap p={1}>
                {filters.map((item) => (
                    <Chip
                        key={item}
                        size="small"
                        clickable
                        sx={{ textTransform: "uppercase" }}
                        variant={filter.includes(item) ? "filled" : "outlined"}
                        label={item}
                        color="tertiary"
                        icon={
                            filter.includes(item) ? (
                                <CancelRoundedIcon />
                            ) : (
                                <AddCircleOutlineRoundedIcon />
                            )
                        }
                        onClick={() => handleFilter(item)}
                    />
                ))}
            </Stack>
            <Divider variant="middle" />
        </Collapse>
    );
}
