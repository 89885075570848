import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useLocation } from "react-router-dom";
import router from "router/router";
import React from "react";
import SvgIcon from "@mui/material/SvgIcon";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import RessourcesSummary from "./RessourcesSummary";
import RessourcesFilter from "./RessourcesFilter";
import FilterButton from "../Button/FilterButton";
import Skeleton from "@mui/material/Skeleton";
import { FilterProvider } from "../../lib/contexts/FilterContext";

export default function ContentPage() {
    const [page, setPage] = React.useState(null);
    const location = useLocation().pathname;
    const [openFilter, setOpenFilter] = React.useState(false);

    React.useEffect(() => {
        router.routes[0].children.map(
            (item) =>
                "/" + item.path === location &&
                setPage({
                    name: item.name,
                    icon: item.icon,
                    type: item.path,
                })
        );
    }, [location]);

    return (
        <Stack>
            <Box
                display="flex"
                flexDirection="row"
                justifyContent="space-between"
                alignItems="center"
                mx={1}
            >
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 400,
                        lineHeight: 1.334,
                        display: "flex",
                        letterSpacing: "0em",
                        color: "primary.main",
                        mt: 1,
                    }}
                >
                    {page ? (
                        <SvgIcon
                            sx={{
                                my: "auto",
                                fontSize: "inherit",
                                color: "inherit",

                                mr: 1,
                            }}
                            component={page.icon}
                        />
                    ) : (
                        <Skeleton />
                    )}

                    {page ? page.name : <Skeleton />}
                </Typography>

                {page && page.name === "Vidéo" && (
                    <FilterButton
                        isOpen={openFilter}
                        variant="outlined"
                        color="tertiary"
                        size="small"
                        onClick={() => setOpenFilter(!openFilter)}
                    />
                )}
            </Box>
            <Divider
                sx={{
                    borderColor: "secondary.main",
                    borderWidth: 1,
                    width: "5%",
                    marginLeft: 5,
                }}
            />
            <FilterProvider>
                {page && page.name === "Vidéo" && (
                    <RessourcesFilter isOpen={openFilter} />
                )}

                <RessourcesSummary type={page && page.type} />
            </FilterProvider>
        </Stack>
    );
}
