import CardHeader from "@mui/material/CardHeader";
import React, { useContext } from "react";
import IconButton from "@mui/material/IconButton";
import MoreVertRoundedIcon from "@mui/icons-material/MoreVertRounded";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { UserDataContext } from "../../../lib/contexts/UserDataContext";
import { useNavigate } from "react-router-dom";

export default function RessourceCardHeader({ data }) {
    const nav = useNavigate();

    const {
        favorisVideo,
        setFavorisVideo,
        favorisAnatomie3D,
        setFavorisAnatomie3D,
    } = useContext(UserDataContext);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePlay = () => {
        setAnchorEl(null);
        nav("./" + data.id, { state: { play: true } });
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleFavori = () => {
        setAnchorEl(null);
        if (data.categorie === "Video") {
            setFavorisVideo((prev) =>
                favorisVideo.includes(data.id)
                    ? prev.filter((item) => item !== data.id)
                    : [...prev, data.id]
            );
        } else {
            setFavorisAnatomie3D((prev) =>
                favorisAnatomie3D.includes(data.id)
                    ? prev.filter((item) => item !== data.id)
                    : [...prev, data.id]
            );
        }
    };

    return (
        <CardHeader
            title={data.name}
            subheader={
                data &&
                data.labels.map((item, index) =>
                    index === 0 ? item : " - " + item
                )
            }
            sx={{
                px: 1,
                pt: 0.5,
                pb: 0,
                "& .MuiCardHeader-title": {
                    fontSize: 20,
                    fontWeight: "800",
                    mt: 0.2,
                    lineHeight: 1.1,
                    color: "primary.main",
                },
                "& .MuiCardHeader-subheader": {
                    fontSize: 13,
                    lineHeight: 0.6,
                    color: "secondary.dark",
                    my: 0.5,
                    textTransform: "lowercase",
                },
            }}
            action={
                <>
                    <IconButton onClick={handleClick}>
                        <MoreVertRoundedIcon />
                    </IconButton>
                    <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                        <MenuItem onClick={handlePlay}>
                            {data.categorie === "Video"
                                ? "Lecture"
                                : "Visionner"}
                        </MenuItem>
                        <MenuItem onClick={handleFavori}>
                            {favorisVideo &&
                            favorisVideo
                                .concat(favorisAnatomie3D)
                                .includes(data.id)
                                ? "Retirer des favoris"
                                : "Ajouter aux favoris"}
                        </MenuItem>
                    </Menu>
                </>
            }
        />
    );
}
