import AppBar from "@mui/material/AppBar";
import Divider from "@mui/material/Divider";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import logo from "./logo.svg";
import useMediaQuery from "@mui/material/useMediaQuery/index";
import Search from "./Search/Search";
import { AuthenticatedTemplate } from "@azure/msal-react";

export default function Header() {
    const littleSize = useMediaQuery("(max-width: 388px");

    return (
        <AppBar
            component={"header"}
            position="static"
            sx={{ borderBottom: "solid 4px #9e8638" }}
        >
            <Toolbar sx={{ my: 0.5 }}>
                <img
                    src={logo}
                    alt="logo EPB"
                    width={littleSize ? 80 : "inherit"}
                />
                <Divider
                    orientation="vertical"
                    flexItem
                    sx={{
                        borderColor: "#9e8638",
                        mx: 1,
                        my: 2.2,
                    }}
                />
                <Typography
                    variant="h6"
                    sx={{ flexGrow: 1, fontSize: littleSize && 16 }}
                >
                    Portail de formation
                </Typography>
                <AuthenticatedTemplate>
                    <Search />
                </AuthenticatedTemplate>
            </Toolbar>
        </AppBar>
    );
}
