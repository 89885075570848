import { PublicClientApplication } from "@azure/msal-browser";

const configMSAL = {
    auth: {
        clientId: process.env.REACT_APP_MSALID,
        authority:
            "https://login.microsoftonline.com/3f5cbf13-3b09-4112-8daf-d355ff7c14a3/", // This is a URL (e.g. https://login.microsoftonline.com/{your tenant ID})
        redirectUri: process.env.REACT_APP_REDIRECT_URI,
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
};

const instanceMSAL = new PublicClientApplication(configMSAL);

export default instanceMSAL;
